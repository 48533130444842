// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Widgets2 from "../../blocks/Widgets2/src/Widgets2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import Leaderboard from "../../blocks/Leaderboard/src/Leaderboard";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import DataMigration2 from "../../blocks/DataMigration2/src/DataMigration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import Gamification from "../../blocks/Gamification/src/Gamification";
import Notes from "../../blocks/Notes/src/Notes";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import Gallery from "../../blocks/Gallery/src/Gallery";
import KarmaPoints from "../../blocks/KarmaPoints/src/KarmaPoints";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import DeliveryEstimator13 from "../../blocks/DeliveryEstimator13/src/DeliveryEstimator13";
import Notifications from "../../blocks/notifications/src/Notifications";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Referrals from "../../blocks/Referrals/src/Referrals";
import GoalManagement from "../../blocks/GoalManagement/src/GoalManagement";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import PrivateChat from "../../blocks/PrivateChat/src/PrivateChat";
import DigitalSignatures from "../../blocks/DigitalSignatures/src/DigitalSignatures";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import Location from "../../blocks/location/src/Location";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import ProductRecommendationEngine from "../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import DailyScheduleNotifications from "../../blocks/DailyScheduleNotifications/src/DailyScheduleNotifications";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import Search from "../../blocks/search/src/Search";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import SummaryCard from "../../blocks/SummaryCard/src/SummaryCard";
import CreateComment from "../../blocks/comments/src/CreateComment";



const routeMap = {
Widgets2:{
 component:Widgets2,
path:"/Widgets2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
DataMigration2:{
 component:DataMigration2,
path:"/DataMigration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Notes:{
 component:Notes,
path:"/Notes"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
KarmaPoints:{
 component:KarmaPoints,
path:"/KarmaPoints"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
DeliveryEstimator13:{
 component:DeliveryEstimator13,
path:"/DeliveryEstimator13"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
GoalManagement:{
 component:GoalManagement,
path:"/GoalManagement"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
PrivateChat:{
 component:PrivateChat,
path:"/PrivateChat"},
DigitalSignatures:{
 component:DigitalSignatures,
path:"/DigitalSignatures"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Location:{
 component:Location,
path:"/Location"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
ProductRecommendationEngine:{
 component:ProductRecommendationEngine,
path:"/ProductRecommendationEngine"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ImageThumbnail:{
 component:ImageThumbnail,
path:"/ImageThumbnail"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
Search:{
 component:Search,
path:"/Search"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
SummaryCard:{
 component:SummaryCard,
path:"/SummaryCard"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},

  Home: {
component:ApiIntegration,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
